import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const AddIntrospection = React.lazy(() => import("./AddIntrospection"));
const IntrospectionDetails = React.lazy(() => import("./IntrospectionDetails"));
export const introspectionConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: "/introspection",
    element: <AddIntrospection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/introspection/:introspectionId",
    element: <IntrospectionDetails />,
  },
];
