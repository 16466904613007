import {GET_QUOTES, GET_QUOTES_ERROS} from 'shared/constants/ActionTypes';

const initialState = {
  loading: false,
  error: null,
};
export const getDailyQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUOTES:
      return {...state, getDailyQuotes: action.payload, loading: false};
    case GET_QUOTES_ERROS:
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
};
