import {
  GET_EVENTS_CATEGORY,
  GET_EVENTS_CATEGORY_ERROR,

} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const eventsCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_CATEGORY:
      return { ...state, eventsCategoryData: action.payload, loading: false };
    case GET_EVENTS_CATEGORY_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
