import {
    GET_ALBUM_MEDIA_AUDIO,
    GET_ALBUM_MEDIA_AUDIO_ERROR,
    GET_AUDIO_ALBUM_ID,
    GET_AUDIO_ALBUM_ID_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
    loading: false,
    error: null,
};
export const getAlbumAudioReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALBUM_MEDIA_AUDIO:
            return {
                ...state,
                getAlbumAudioData: action.payload,
                loading: false,
            };
        case GET_ALBUM_MEDIA_AUDIO_ERROR:
            return { ...state, error: action.payload, loading: false };

        case GET_AUDIO_ALBUM_ID:
            return {
                ...state,
                getSingleAlbumData: action.payload,
                loading: false,
            };
        case GET_AUDIO_ALBUM_ID_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
