import {
  GET_VIDEO_SERIES,
  GET_VIDEO_SERIES_ERROR,
  GET_VIDEO_SERIES_ID,
  GET_VIDEO_SERIES__ID_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getVideoSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_SERIES:
      return { ...state, getVideoSeriesData: action.payload, loading: false };
    case GET_VIDEO_SERIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
export const getVideoSeriesByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_SERIES_ID:
      return { ...state, getVideoSeriesIdData: action.payload, loading: false };
    case GET_VIDEO_SERIES__ID_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
