import {
  GET_EVENTS_SNEAK,
  GET_EVENTS_SNEAK_ERROR,
} from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const eventsSneakReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_SNEAK:
      return { ...state, eventsSneakData: action.payload, loading: false };
    case GET_EVENTS_SNEAK_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
