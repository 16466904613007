import { GET_CATEGORY, GET_CATEGORY_ERROR } from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getAddCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return { ...state, getAddCategory: action.payload, loading: false };
    case GET_CATEGORY_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
