import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const EventCategory = React.lazy(() => import("./EventCategory"));
const EventCreateView = React.lazy(() => import("./EventCreateView"));
const EventListView = React.lazy(() => import("./EventList/index"));
const AshramwasiEvents = React.lazy(() => import("./AshramwasiEvents"));
export const eventsConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/events/event-category",
        element: <EventCategory />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/events/event-list",
        element: <EventListView />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/events/create-event",
        element: <EventCreateView />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/events/edit-event/:id",
        element: <EventCreateView />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/ashramwasi-events",
        element: <AshramwasiEvents />,
    },
];
