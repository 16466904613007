import { GET_ALL_HEADER_MENU } from "shared/constants/ActionTypes";
const initialState = {
    allHeaderMenu: [],
};
export const HeaderMenu = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_HEADER_MENU:
            return {
                ...state,
                allHeaderMenu: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
