import {GET_SUBCRIBER, GET_SUBCRIBER_ERROR} from 'shared/constants/ActionTypes';

const initialState = {
  loading: false,
  error: null,
};
export const getSubcriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCRIBER:
      return {...state, getSubcriberData: action.payload, loading: false};
    case GET_SUBCRIBER_ERROR:
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
};
