import {
  GET_BLOG,
} from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const getBlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG:
      return { ...state, getBlogData: action.payload, loading: false };
    default:
      return state;
  }
};
