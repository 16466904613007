import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const AddSurvey = React.lazy(() => import("./AddSurvey"));
const SurveyDetails = React.lazy(() => import("./SurveyDetails"));
export const surveyConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: "/survey",
    element: <AddSurvey />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/survey/:surveyId",
    element: <SurveyDetails />,
  },
];
