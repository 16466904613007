import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const Advertisement = React.lazy(() => import("./Advertisement"));
const DailyQuotes = React.lazy(() => import("./DailyQuotes"));
const AmazingMoment = React.lazy(() => import("./AmazingMoment"));
const NewsAndUpdates = React.lazy(() => import("./NewsAndUpdates"));
const ContentBanner = React.lazy(() => import("./ContentBanner"));
const GetInTouch = React.lazy(() => import("./GetInTouch"));
export const dashBoardConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/get-in-touch",
        element: <GetInTouch />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/content-banner",
        element: <ContentBanner />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/advertisement",
        element: <Advertisement />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/daily-quotes",
        element: <DailyQuotes />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/news-and-updates",
        element: <NewsAndUpdates />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/amazing-moment",
        element: <AmazingMoment />,
    },
];
