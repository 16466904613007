import {
  GET_BLOG_COMMENTS,
  GET_BLOG_COMMENTS_ERROR,
} from 'shared/constants/ActionTypes';

const initialState = {
  loading: false,
  error: null,
};
export const getBlogCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG_COMMENTS:
      return {...state, getBlogCommentsData: action.payload, loading: false};
    case GET_BLOG_COMMENTS_ERROR:
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
};
