import {
    // CREATE_EVENTS_BANNER,
    // CREATE_EVENTS_DESC,
    GET_EVENTS_BANNER,
    GET_EVENTS_BANNER_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
    loading: false,
    error: null,
    eventsBannerData: null,
};
export const eventsBannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_BANNER:
            return {
                ...state,
                eventsBannerData: action.payload,
                loading: false,
            };
        case GET_EVENTS_BANNER_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
