import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const SubcriptionPlan = React.lazy(() => import("./SubcriptionPlan"));
const Subscriber = React.lazy(() => import("./Subscriber"));
export const subscriptionConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/subscription/plans",
        element: <SubcriptionPlan />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/subscription/subscribers",
        element: <Subscriber />,
    },


];
