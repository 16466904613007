import {
  GET_EVENTS_TEST,
  GET_EVENTS_TEST_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const eventsTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_TEST:
      return { ...state, eventsTestData: action.payload, loading: false };
    case GET_EVENTS_TEST_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
