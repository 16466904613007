import {
    GET_INTROSPECTION,
    GET_INTROSPECTION_QUESTION,
    GET_INTROSPECTION_RESULT,
} from "shared/constants/ActionTypes";

const initialState = {
    introspectionList: [],
    introspectionDetails: {},
    introspectionResults: [],
};
export const IntrospectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INTROSPECTION:
            return {
                ...state,
                introspectionList: action.payload,
            };
        case GET_INTROSPECTION_QUESTION:
            return {
                ...state,
                introspectionDetails: action.payload,
            };
        case GET_INTROSPECTION_RESULT:
            return {
                ...state,
                introspectionResults: action.payload,
            };
        default:
            return state;
    }
};
