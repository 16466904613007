import { GET_QUIZ, GET_QUIZ_ERROR } from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUIZ:
      return { ...state, getQuizData: action.payload, loading: false };
    case GET_QUIZ_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
