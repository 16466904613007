import { GET_PRODUCT, GET_PRODUCT_ERROR } from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const getProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return { ...state, getProductData: action.payload, loading: false };
    case GET_PRODUCT_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
