import {
  GET_SHORT_VIDEO,
  GET_SHORT_VIDEO_ERROR,
  FETCH_ERROR,
  FETCH_START,
} from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const getShortVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHORT_VIDEO:
      return { ...state, getShortVideoData: action.payload, loading: false };
    case GET_SHORT_VIDEO_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_START:
      return { ...state, loading: true };
    case FETCH_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};
