import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const GamificationVideo = React.lazy(() => import("./GamificationVideo"));
const GamificationSeries = React.lazy(() => import("./GamificationSeries"));
const AddSeriesDetails = React.lazy(() =>
    import("./GamificationSeries/AddSeriesDetails")
);
export const gamificationConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/gamification-video",
        element: <GamificationVideo />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/gamification-series",
        element: <GamificationSeries />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/gamification-series/:id",
        element: <AddSeriesDetails />,
    },
];
