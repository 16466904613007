import {
    CREATE_EVENTS_BANNER,
    CREATE_EVENTS_DESC,
    GET_EVENTS_ID,
    GET_EVENTS_ID_ERROR,

} from "shared/constants/ActionTypes";

const initialState = {
    loading: false,
    error: null,
    getEventsByIdData: null,
};
export const getEventsByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_ID:
            return {
                ...state,
                getEventsByIdData: action.payload,
                loading: false,
            };
        case CREATE_EVENTS_BANNER:
            return {
                ...state,
                getEventsByIdData: action.payload,
                loading: false,
            };
        case CREATE_EVENTS_DESC:
            return {
                ...state,
                // getEventsByIdData: newState,
                loading: false,
            };
        case GET_EVENTS_ID_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
