import React from "react";
import { Navigate } from "react-router-dom";
import { initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import { authRouteConfig } from "./auth";
import { errorPagesConfigs } from "./errorPages";
import { dashBoardConfigs } from "./dashboards";
import { mediasConfigs } from "./medias";
import { userPagesConfigs } from "./userPages";
import { accountPagesConfigs } from "./account";
import { quizConfigs } from "./quiz";
import { surveyConfigs } from "./survey";
import { gamificationConfigs } from "./gamification";
import { masterConfigs } from "./masters";
import { eventsConfigs } from "./events";
import { shopConfigs } from "./shop";
import { subscriptionConfigs } from "./subscription";
import { introspectionConfigs } from "./introspection";

const authorizedStructure = {
    fallbackPath: "/signin",
    unAuthorizedComponent: <Error403 />,
    routes: [
        ...dashBoardConfigs,
        ...mediasConfigs,
        ...accountPagesConfigs,
        ...quizConfigs,
        ...surveyConfigs,
        ...introspectionConfigs,
        ...gamificationConfigs,
        ...userPagesConfigs,
        ...masterConfigs,
        ...eventsConfigs,
        ...shopConfigs,
        ...subscriptionConfigs,
        ...introspectionConfigs
    ],
};

const unAuthorizedStructure = {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
};
const anonymousStructure = {
    routes: errorPagesConfigs.concat([
        {
            path: "/",
            element: <Navigate to={initialUrl} />,
        },
        {
            path: "*",
            element: <Navigate to="/error-pages/error-404" />,
        },
    ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
