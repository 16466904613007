import {
    GET_ALL_ORDERS,
    GET_ORDER_DETAILS,
    UPDATE_ORDER_STATUS,
} from "shared/constants/ActionTypes";

const initialState = {
    getAllOrdersData: [],
    getOrderDetails: null,
};
export const getOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ORDERS:
            return {
                ...state,
                getAllOrdersData: action.payload,
            };
        case GET_ORDER_DETAILS:
            return {
                ...state,
                getOrderDetails: action.payload,
            };
        case UPDATE_ORDER_STATUS:
            return {
                ...state,
                getOrderDetails: {
                    ...state.getOrderDetails,
                    orderItem: [
                        ...state.getOrderDetails.orderItem.map((item) =>
                            item.productTypeId == action.payload.productTypeId
                                ? { ...action.payload }
                                : item
                        ),
                    ],
                },
            };
        default:
            return state;
    }
};
