import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Setting";
import Common from "./Common";
import { subPlanReducer } from "./SubPlan";
import { getEbookReducer } from "./Ebook";
import { getDailyQuotesReducer } from "./DailyQuotes";
import { getSubcriberReducer } from "./Subcriber";
// import { getSurveyQuestionReducer } from "./SurveyQuestion";
import { getOrderReducer } from "./OrderManagement";
import { getBlogReducer } from "./Blog";
import { getBlogCommentsReducer } from "./BlogComments";
import { getMediaVideoReducer } from "./MediaVideo";
import { getCategoryAudioReducer, getMediaAudioReducer } from "./MediaAudio";
import { getMagazinesReducer } from "./Magazines";
import { getAmazingMomentReducer } from "./AmazingMoment";
import { getNewsAndUpdateReducer } from "./NewsAndUpdates";
import { getEventReducer } from "./Events";
import { getBannerReducer } from "./Banner";
import { getAdvertisementReducer } from "./Advertisement";
import { getSurveyReducer } from "./Survey";
import { getQuizReducer } from "./Quiz";
import { getQuizQuestionReducer } from "./QuizQuestion";
import { getQuizFeedbackReducer } from "./Quizfeedback";
import { gamificationReducer } from "./Gamification";
import { getAshramwashiEventsReducer } from "./AshramwashiEvents";
import { getShortVideoReducer } from "./ShortVideo";
import { getProductReducer } from "./Product";
import { getAlbumAudioReducer } from "./AudioAlbum";
import { getAddCategoryReducer } from "./AddCategory";
import { authorMasterReducer } from "./AuthorMaster";
import { ageCategoryDataReducer } from "./AgeCategory";
import { premiumListDataReducer } from "./Premium";
import { eventsBannerReducer } from "./Events/banner";
import { eventsDescReducer } from "./Events/Description";
import { eventsTestReducer } from "./Events/Testinomial";
import { eventsSneakReducer } from "./Events/Sneak";
import { eventsCategoryReducer } from "./Events/Category";
import { eventsFaqsReducer } from "./Events/Faqs";
import { eventsSessionReducer } from "./Events/Session";
import { getInTouchReducer } from "./GetInTouch";
import {
    getVideoSeriesByIdReducer,
    getVideoSeriesReducer,
} from "./VideoSeries";
import { getEventsByIdReducer } from "./Events/getEventsById";
import { HeaderMenu } from "./HeaderMenu";
import { IntrospectionReducer } from "./Introspection";

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        common: Common,
        subPlanReducer: subPlanReducer,
        getEbookReducer: getEbookReducer,
        getDailyQuotesReducer: getDailyQuotesReducer,
        getSubcriberReducer: getSubcriberReducer,
        getSurveyReducer: getSurveyReducer,
        // getSurveyQuestionReducer: getSurveyQuestionReducer,
        getOrderReducer: getOrderReducer,
        getBlogReducer: getBlogReducer,
        getBlogCommentsReducer: getBlogCommentsReducer,
        getMediaVideoReducer: getMediaVideoReducer,
        getMediaAudioReducer: getMediaAudioReducer,
        getMagazinesReducer: getMagazinesReducer,
        getCategoryAudioReducer: getCategoryAudioReducer,
        getAmazingMomentReducer: getAmazingMomentReducer,
        getNewsAndUpdateReducer: getNewsAndUpdateReducer,
        getEventReducer: getEventReducer,
        getBannerReducer: getBannerReducer,
        getAdvertisementReducer: getAdvertisementReducer,
        getQuizReducer: getQuizReducer,
        getQuizQuestionReducer: getQuizQuestionReducer,
        getQuizFeedbackReducer: getQuizFeedbackReducer,
        gamificationReducer: gamificationReducer,
        getAshramwashiEventsReducer: getAshramwashiEventsReducer,
        getShortVideoReducer: getShortVideoReducer,
        getProductReducer: getProductReducer,
        getAlbumAudioReducer: getAlbumAudioReducer,
        getAddCategoryReducer: getAddCategoryReducer,
        authorMasterReducer: authorMasterReducer,
        ageCategoryDataReducer: ageCategoryDataReducer,
        premiumListDataReducer: premiumListDataReducer,
        eventsBannerReducer: eventsBannerReducer,
        eventsDescReducer: eventsDescReducer,
        eventsTestReducer: eventsTestReducer,
        eventsSneakReducer: eventsSneakReducer,
        eventsCategoryReducer: eventsCategoryReducer,
        eventsFaqsReducer: eventsFaqsReducer,
        eventsSessionReducer: eventsSessionReducer,
        getInTouchReducer: getInTouchReducer,
        getVideoSeriesReducer: getVideoSeriesReducer,
        getVideoSeriesByIdReducer: getVideoSeriesByIdReducer,
        getEventsByIdReducer: getEventsByIdReducer,
        HeaderMenu : HeaderMenu,
        IntrospectionReducer : IntrospectionReducer,
    });
export default reducers;
