import {
  GET_GAMIFICATION,
  GET_GAMIFICATION_ERROR,
  GET_GAMIFICATION_QUIZ,
  GET_GAMIFICATION_QUIZ_QUES,
  GET_GAMI_SERIES,
  GET_GAMI_SERIES_ID,
  FETCH_ERROR,
  FETCH_START,
} from "shared/constants/ActionTypes";
const initialState = {
  gamificationVideoList: [],
  gamificationSeriesVideoList: [],
  getByIdGamificationSeriesVideoList: [],
  gamificationQuiz: {},
  gamificationQuestionList: [],
  loading: false,
  error: null,
};
export const gamificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAMIFICATION_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_START:
      return { ...state, loading: true };
    case FETCH_ERROR:
      return { ...state, loading: false };
    case GET_GAMIFICATION:
      return {
        ...state,
        gamificationVideoList: action.payload,
        loading: false,
      };
    case GET_GAMI_SERIES_ID:
      return {
        ...state,
        getByIdGamificationSeriesVideoList: action.payload,
      };
    case GET_GAMI_SERIES:
      return {
        ...state,
        gamificationSeriesVideoList: action.payload,
        loading: false,
      };
    case GET_GAMIFICATION_QUIZ:
      return {
        ...state,
        gamificationQuiz: action.payload,
        loading: false,
      };
    case GET_GAMIFICATION_QUIZ_QUES:
      return {
        ...state,
        gamificationQuestionList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
