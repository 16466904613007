import { GET_BANNER, GET_BANNER_ERROR } from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER:
      return { ...state, getBannerData: action.payload, loading: false };
    case GET_BANNER_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
