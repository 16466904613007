import { GET_NEWS, GET_NEWS_ERROR } from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getNewsAndUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS:
      return { ...state, getNewsAndUpdateData: action.payload, loading: false };
    case GET_NEWS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
