import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const AddQuiz = React.lazy(() => import("./AddQuiz"));
const QuizQuestion = React.lazy(() => import("./QuizQuestion"));
const QuizFeedback = React.lazy(() => import("./QuizFeedback"));
/* const Invoice1 = React.lazy(() => import("./Invoice1")); */

export const quizConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: "/quiz/add-quiz",
    element: <AddQuiz />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/quiz/quiz-question",
    element: <QuizQuestion />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: "/quiz/quiz-feedback",
    element: <QuizFeedback />,
  },
  /* {
    permittedRole: RoutePermittedRole.user,
    path: "/ecommerce/invoice-1",
    element: <Invoice1 />,
  }, */
];
