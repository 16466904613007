import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const OrderManagement = React.lazy(() => import("./OrderManagement"));
const OrderDetails = React.lazy(() => import("./OrderManagement/orderDetails"));
const Product = React.lazy(() => import("./Product"));
export const shopConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/orders",
        element: <OrderManagement />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/orders/:orderId",
        element: <OrderDetails />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/shop/product",
        element: <Product />,
    },
];
