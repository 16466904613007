import React, {useState} from 'react';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import AppTooltip from '../AppTooltip';
//import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
/* import Drawer from '@mui/material/Drawer';
import AppMessageContent from './AppMessageContent'; */
//import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';

const AppMessages = ({
  /*  drawerPosition, */
  tooltipPosition,
  isMenu,
  /*   sxMessageContentStyle, */
}) => {
  const [, /* showMessage */ setShowMessage] = useState(false);
  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowMessage(true)}>
         {/*  Message */}
        </Box>
      ) : (
        <AppTooltip title='Message' placement={tooltipPosition}>
          <IconButton
            className='icon-btn'          
            onClick={() => setShowMessage(true)}
            size='large'
          >
          
          </IconButton>
        </AppTooltip>
      )}
    </>
  );
};

export default AppMessages;

AppMessages.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppMessages.propTypes = {
  sxMessageContentStyle: PropTypes.object,
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
};
