import {
  GET_ADVERTISEMENT,
  GET_ADVERTISEMENT_ERROR,
} from "shared/constants/ActionTypes";

const initialeState = {
  loading: false,
  error: null,
};

export const getAdvertisementReducer = (state = initialeState, action) => {
  switch (action.type) {
    case GET_ADVERTISEMENT:
      return { ...state, getAdvertisementData: action.payload, loading: false };
    case GET_ADVERTISEMENT_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
