import React from "react";
import /* BsBriefcase,
  BsCart4,
  BsChatDots,
  BsQuestionDiamond, */
/*  BsCurrencyBitcoin, */
"react-icons/bs";
import { ReactComponent as Sub } from "../assets/icon/subscribe.svg";
import { ReactComponent as Amazingmoment } from "../assets/icon/amazing-moment.svg";
import { ReactComponent as Magazine } from "../assets/icon/magazine.svg";
import { ReactComponent as Contentbanner } from "../assets/icon/content-banner.svg";
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import { BiMessageEdit } from "react-icons/bi";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
//import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
const routesConfig = [
    {
        id: "advertisement",
        title: "Advertisement",
        messageId: "sidebar.advertisement",
        type: "item",
        icon: <CampaignOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/advertisement",
    },
    {
        id: "amazingmoment",
        title: "Amazing Moment",
        messageId: "sidebar.amazingmoment",
        type: "item",
        icon: <Amazingmoment sx={{ color: "#00455E" }} />,
        url: "/amazing-moment",
    },

    {
        id: "contentbanner",
        title: "Content Banner",
        messageId: "sidebar.contentbanner",
        type: "item",
        icon: <Contentbanner sx={{ color: "#00455E" }} />,
        url: "/content-banner",
    },
    {
        id: "getInTouch",
        title: "Mail Subscribers",
        messageId: "sidebar.getInTouch",
        type: "item",
        icon: <AlternateEmailOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/get-in-touch",
    },
    {
        id: "dailyquotes",
        title: "Daily Quotes",
        messageId: "sidebar.dailyquotes",
        type: "item",
        icon: <FormatQuoteOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/daily-quotes",
    },
    {
        id: "newsupdate",
        title: "News And Updates",
        messageId: "sidebar.newsandupdates",
        type: "item",
        icon: <FeedOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/news-and-updates",
    },
    {
        id: "survey",
        title: "Survey",
        messageId: "sidebar.survey",
        type: "item",
        icon: <PollOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/survey",
    },
    {
        id: "introspection",
        title: "Introspection",
        messageId: "sidebar.introspection",
        type: "item",
        icon: <PollOutlinedIcon sx={{ color: "#00455E" }} />,
        url: "/introspection",
    },
    {
        id: "events",
        title: "Events",
        messageId: "sidebar.event",
        type: "collapse",
        icon: <EventAvailableOutlinedIcon sx={{ color: "#00455E" }} />,
        children: [
            {
                id: "event_category",
                title: "Category",
                messageId: "sidebar.event.category",
                type: "item",
                url: "/events/event-category",
            },
            {
                id: "event_list",
                title: "EventList",
                messageId: "sidebar.event.list",
                type: "item",
                url: "/events/event-list",
            },
        ],
    },
    {
        id: "gamification",
        title: "Gamification",
        messageId: "sidebar.gamification",
        type: "collapse",
        icon: (
            <LeaderboardOutlinedIcon
                sx={{ color: "#00455E", fontSize: "23px" }}
            />
        ),
        children: [
            {
                id: "gamificationseries",
                title: "Gamification Series",
                messageId: "sidebar.gamification.gamificationseries",
                type: "item",
                url: "/gamification-series",
            },
            {
                id: "gamificationvideo",
                title: "Gamification Video",
                messageId: "sidebar.gamification.gamificationvideo",
                type: "item",
                url: "/gamification-video",
            },
            /*   {
        id: "gamificationquiz",
        title: "Gamification Quiz",
        messageId: "sidebar.gamification.gamificationquiz",
        type: "item",
        url: "/gamification/gamification-quiz",
      },

      {
        id: "gamificationquestionquiz",
        title: "Gamification Question",
        messageId: "sidebar.gamification.gamificationquestionquiz",
        type: "item",
        url: "/gamification/gamification-question-quiz",
      }, */
        ],
    },

    {
        id: "quiz",
        title: "Quiz",
        messageId: "sidebar.quiz",
        type: "collapse",
        icon: <QuizOutlinedIcon sx={{ color: "#00455E", fontSize: "23px" }} />,
        children: [
            {
                id: "addquiz",
                title: "Add Quiz",
                messageId: "sidebar.quiz.addquiz",
                type: "item",
                url: "/quiz/add-quiz",
            },
            {
                id: "quizquestion",
                title: "Quiz Question",
                messageId: "sidebar.quiz.quizquestion",
                type: "item",
                url: "/quiz/quiz-question",
            },
            {
                id: "quizfeedback",
                title: "Quiz Feedback",
                messageId: "sidebar.quiz.quizfeedback",
                type: "item",
                url: "/quiz/quiz-feedback",
            },
        ],
    },
    {
        id: "shop",
        title: "Shop",
        messageId: "sidebar.shop",
        type: "collapse",
        icon: <QuizOutlinedIcon sx={{ color: "#00455E", fontSize: "23px" }} />,
        children: [
            {
                id: "product",
                title: "Product",
                messageId: "sidebar.product",
                type: "item",
                icon: (
                    <Inventory2OutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                url: "/shop/product",
            },
            {
                id: "OrderManagement",
                title: "Order Management",
                messageId: "sidebar.ordermanagement",
                type: "item",
                icon: (
                    <LocalShippingOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                url: "/orders",
            },
        ],
    },

    {
        id: "subscription",
        title: "Subscription",
        messageId: "sidebar.subscription",
        type: "collapse",
        icon: <PollOutlinedIcon sx={{ color: "#00455E" }} />,
        children: [
            {
                id: "subcriptionplan",
                title: "SubcriptionPlan",
                messageId: "sidebar.subscriptionPlan",
                type: "item",
                icon: (
                    <SubscriptionsOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                url: "/subscription/plans",
            },
            {
                id: "subscriber",
                title: "Subscriber",
                messageId: "sidebar.subscribers",
                type: "item",
                icon: <Sub sx={{ color: "#00455E", fontSize: "23px" }} />,
                url: "/subscription/subscribers",
            },
        ],
    },

    {
        id: "master",
        title: "Master",
        messageId: "sidebar.master",
        type: "collapse",
        icon: (
            <SupervisorAccountIcon
                sx={{ color: "#00455E", fontSize: "23px" }}
            />
        ),
        children: [
            {
                id: "authormaster",
                title: "Authormaster",
                messageId: "sidebar.master.authormaster",
                type: "item",
                url: "/master/author",
            },
            {
                id: "category",
                title: "category",
                messageId: "sidebar.master.category",
                type: "item",
                url: "/master/category",
            },
            {
                id: "agecategory",
                title: "Age Category",
                messageId: "sidebar.master.agecategory",
                type: "item",
                url: "/master/age",
            },
            {
                id: "premiumcategory",
                title: "Premium Category",
                messageId: "sidebar.master.premium",
                type: "item",
                url: "/master/premium",
            },
        ],
    },
    // {
    //     id: "headerMenu",
    //     title: "Header Menu",
    //     messageId: "sidebar.headerMenu",
    //     type: "item",
    //     icon: <EventAvailableOutlinedIcon sx={{ color: "#00455E" }} />,
    //     url: "/header-menu",
    // },
    {
        id: "media-list",
        title: "Medias",
        messageId: "sidebar.media",
        type: "group",
        children: [
            {
                id: "media_video",
                title: "Video",
                messageId: "sidebar.media.videos",
                type: "collapse",
                icon: (
                    <VideoFileOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                children: [
                    {
                        id: "create_series",
                        title: "create series",
                        messageId: "sidebar.media.videoSeries",
                        type: "item",
                        url: "/videos/series",
                    },
                    {
                        id: "serieslist",
                        title: "SeriesList",
                        messageId: "sidebar.media.videoSingle",
                        type: "item",
                        url: "videos/single",
                    },
                ],
            },
            {
                id: "media_audio",
                title: "Audio",
                messageId: "sidebar.media.audios",
                type: "collapse",
                icon: (
                    <VideoFileOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                children: [
                    {
                        id: "audioalbum",
                        title: "Audio Album",
                        messageId: "sidebar.media.audioAlbum",
                        type: "item",
                        icon: (
                            <AudioFileOutlinedIcon
                                sx={{ color: "#00455E", fontSize: "23px" }}
                            />
                        ),
                        url: "/audios/album",
                    },
                    {
                        id: "mediaaudio",
                        title: "Media Audio",
                        messageId: "sidebar.media.audioSingle",
                        type: "item",
                        icon: (
                            <AudioFileOutlinedIcon
                                sx={{ color: "#00455E", fontSize: "23px" }}
                            />
                        ),
                        url: "/audios/single",
                    },
                ],
            },
            {
                id: "media_blogs",
                title: "Blogs",
                messageId: "sidebar.media.blogs",
                type: "collapse",
                icon: (
                    <BiMessageEdit
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                children: [
                    {
                        id: "addblog",
                        title: "Add Blog",
                        messageId: "sidebar.media.blogs.list",
                        type: "item",
                        url: "/blogs",
                    },
                    {
                        id: "blogstatus",
                        title: "Blog Status",
                        messageId: "sidebar.media.blogs.comments",
                        type: "item",
                        url: "/comments",
                    },
                ],
            },
            {
                id: "media_ebook",
                title: "Ebook",
                messageId: "sidebar.media.ebook",
                type: "item",
                icon: (
                    <MenuBookOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                url: "/books",
            },
            {
                id: "media_magazines",
                title: "Magazines",
                messageId: "sidebar.media.magazines",
                type: "item",
                icon: <Magazine sx={{ color: "#00455E" }} />,
                url: "/magazines",
            },
            {
                id: "media_reels",
                title: "Reels",
                messageId: "sidebar.media.reels",
                type: "item",
                icon: (
                    <AudioFileOutlinedIcon
                        sx={{ color: "#00455E", fontSize: "23px" }}
                    />
                ),
                url: "/reels",
            },
        ],
    },

    {
        id: "ashramwasiPortal",
        title: "Ashramwasi Portal",
        messageId: "sidebar.ashramwasi",
        type: "group",
        children: [
            {
                id: "ashramwasievents",
                title: "AshramwasiEvents",
                messageId: "sidebar.ashramwasievents",
                type: "item",
                icon: <EventAvailableOutlinedIcon sx={{ color: "#00455E" }} />,
                url: "/ashramwasi-events",
            },
        ],
    },
];
export default routesConfig;
