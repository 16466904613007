import {
  CREATE_SUB_PLAN,
  CREATE_SUB_PLAN_ERROR,
  GET_ALL_SUB_PLAN,
  GET_ALL_SUB_PLAN_ERRORS,
} from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const subPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUB_PLAN:
      return { ...state, GetSubPlanData: action.payload, loading: false };
    case GET_ALL_SUB_PLAN_ERRORS:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
export const createSunPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUB_PLAN:
      return { ...state, createSubPan: action.payload, loading: false };
    case CREATE_SUB_PLAN_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
