import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const AuthorMaster = React.lazy(() => import("./AuthorMaster"));
// const Category = React.lazy(() => import("./Category"));
const CategoryAll = React.lazy(() => import("./CategoryAll"));
const AgeCategory = React.lazy(() => import("./AgeCategory"));
const PremiumType = React.lazy(() => import("./PremiumType"));
export const masterConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: "/master/author",
        element: <AuthorMaster />,
    },
    // {
    //     permittedRole: RoutePermittedRole.user,
    //     path: "/master/category",
    //     element: <Category />,
    // },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/master/category",
        element: <CategoryAll />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/master/age",
        element: <AgeCategory />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/master/premium",
        element: <PremiumType />,
    },
    //   {
    //     permittedRole: RoutePermittedRole.user,
    //     path: "/header-menu",
    //     element: <HeaderMenuMaster />,
    // },
];
