import {
    GET_EVENTS,
    GET_EVENTS_ERROR,
    FETCH_ERROR,
    FETCH_START,
    GET_ALL_LOCATIONS,
} from "shared/constants/ActionTypes";

const initialState = {
    loading: false,
    error: null,
    allLocations: [],
};
export const getEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS:
            return { ...state, getEventData: action.payload, loading: false };
        case GET_ALL_LOCATIONS:
            return {
                ...state,
                allLocations: action.payload,
                loading: false,
            };
        case GET_EVENTS_ERROR:
            return { ...state, error: action.payload, loading: false };
        case FETCH_START:
            return { ...state, loading: true };
        case FETCH_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};
