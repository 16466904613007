import {
  GET_QUIZ_QUES,
  GET_QUIZ_QUES_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getQuizQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUIZ_QUES:
      return {
        ...state,
        getQuizQuesData: action.payload,
        loading: false,
      };
    case GET_QUIZ_QUES_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
