import axios from "axios";
import { baseURL } from "shared/constants/AppConst";
// import configureStore from "redux/store";
// import { SHOW_MESSAGE } from "shared/constants/ActionTypes";
// const store = configureStore();
const jwtAxios = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});
jwtAxios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response && err.response.data.httpStatus === "BAD_REQUEST") {
            if (err.response.data?.data?.errorMessage === "Token issue" || err.response.data?.data?.errorMessage === "TOKEN_ISSUE") {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
        }
        return Promise.reject(err);
    }
);
export const setAuthToken = (token) => {
    if (token) {
        jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorage.setItem("token", token);
    } else {
        delete jwtAxios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
    }
};

export default jwtAxios;
