import { GET_FEEDBACK, GET_FEEDBACK_ERROR } from "shared/constants/ActionTypes";
const initialState = {
  loading: false,
  error: null,
};
export const getQuizFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK:
      return {
        ...state,
        getQuizFeedbackData: action.payload,
        loading: false,
      };
    case GET_FEEDBACK_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
