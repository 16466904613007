import {
  GET_AGE_CATEGORY,
  GET_AGE_CATEGORY_ERROR,
  FETCH_ERROR,
  FETCH_START,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const ageCategoryDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGE_CATEGORY:
      return { ...state, ageCategoryData: action.payload, loading: false };
    case GET_AGE_CATEGORY_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_START:
      return { ...state, loading: true };
    case FETCH_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};
