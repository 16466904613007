import React from "react";
import { toggleNavCollapsed } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import AppScrollbar from "../../../AppScrollbar";
import MainSidebar from "../../components/MainSidebar";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import VerticalNav from "../../components/VerticalNav";
import SidebarWrapper from "./SidebarWrapper";
import { useLayoutContext } from "../../../../utility/AppContextProvider/LayoutContextProvider";
import UserInfo from "../../components/UserInfo";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";

const AppSidebar = (props) => {
    const dispatch = useDispatch();
    const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
    const { footer, footerType } = useLayoutContext();

    const { sidebarTextColor } = useSidebarContext();

    const handleToggleDrawer = () => {
        dispatch(toggleNavCollapsed());
    };

    return (
        <>
            <Hidden xlUp>
                <Drawer
                    anchor={props.position}
                    open={navCollapsed}
                    onClose={() => handleToggleDrawer()}
                    classes={{
                        root: clsx(props.variant),
                        paper: clsx(props.variant),
                    }}
                    style={{ position: "absolute" }}
                >
                    <SidebarWrapper className="mini-toggle-sidebar">
                        <MainSidebar>
                            <UserInfo color={sidebarTextColor} />
                            <AppScrollbar
                                sx={{
                                    py: 2,
                                    height: "calc(100vh - 70px) !important",
                                }}
                                scrollToTop={false}
                            >
                                <VerticalNav />
                            </AppScrollbar>
                        </MainSidebar>
                    </SidebarWrapper>
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <SidebarWrapper className="mini-toggle-sidebar">
                    <MainSidebar>
                        <UserInfo color={sidebarTextColor} />
                        <AppScrollbar
                            className={clsx({
                                "has-footer-fixed":
                                    footer && footerType === "fixed",
                            })}
                            sx={{
                                py: 2,
                                height: "calc(100vh - 70px) !important",
                                "&.has-footer-fixed": {
                                    height: "calc(100vh - 117px) !important",
                                },
                            }}
                            scrollToTop={false}
                        >
                            <VerticalNav />
                        </AppScrollbar>
                    </MainSidebar>
                </SidebarWrapper>
            </Hidden>
        </>
    );
};
export default AppSidebar;

AppSidebar.defaultProps = {
    variant: "",
    position: "left",
};

AppSidebar.propTypes = {
    position: PropTypes.string,
    variant: PropTypes.string,
};
