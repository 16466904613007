import {
  GET_EVENTS_SESSION,
  GET_EVENTS_SESSION_ERROR,

} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const eventsSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_SESSION:
      return { ...state, eventsSessionData: action.payload, loading: false };
    case GET_EVENTS_SESSION_ERROR:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
