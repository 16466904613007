import {
  GET_CATEGORY_AUDIO,
  GET_CATEGORY_AUDIO_ERROR,
  GET_MEDIA_AUDIO,
  GET_MEDIA_AUDIO_ERROR,
  FETCH_ERROR,
  FETCH_START,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const getMediaAudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDIA_AUDIO:
      return { ...state, getMediaAudioData: action.payload, loading: false };
    case GET_MEDIA_AUDIO_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_START:
      return { ...state, loading: true };
    case FETCH_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export const getCategoryAudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_AUDIO:
      return { ...state, getCategoryAudioData: action.payload, loading: false };
    case GET_CATEGORY_AUDIO_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
