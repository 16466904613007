import {
  GET_EVENTS_FAQS,
  GET_EVENTS_FAQS_ERROR,
} from "shared/constants/ActionTypes";

const initialState = {
  loading: false,
  error: null,
};
export const eventsFaqsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_FAQS:
      return { ...state, eventsFaqsData: action.payload, loading: false };
    case GET_EVENTS_FAQS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
