import { useDispatch } from "react-redux";
import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
} from "shared/constants/ActionTypes";
import jwtAxios, { setAuthToken } from "./index";
import { baseURLAuth } from "shared/constants/AppConst";

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);
const isTokenExpire = (token) => {
    const { exp } = jwt_decode(token);
    const expirationTime = exp * 1000;
    const now = Date.now();
    return now > expirationTime;
};

const JWTAuthAuthProvider = ({ children }) => {
    const [jwtAuthData, setJWTAuthData] = useState({
        user: null,
        isAuthenticated: false,
        isLoading: true,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            var tokenExpired = isTokenExpire(token);
            if (tokenExpired) {
                setJWTAuthData({
                    user: null,
                    isLoading: false,
                    isAuthenticated: false,
                });
                setAuthToken();
            } else {
                setJWTAuthData({
                    user: "suneel",
                    isLoading: false,
                    isAuthenticated: true,
                });
                setAuthToken(token);
            }
        } else {
            setJWTAuthData({
                user: null,
                isLoading: false,
                isAuthenticated: false,
            });
            // const getAuthUser = () => {
            //   const token = localStorage.getItem("token");
            //   const role = localStorage.getItem("role");
            //   if (!token || !role) {
            //     setJWTAuthData({
            //       user: undefined,
            //       isLoading: false,
            //       isAuthenticated: false,
            //     });
            //     localStorage.clear();
            //     return;
            //   }
            //   setAuthToken(token);
            //   jwtAxios
            //     // .get('bvc/userDetail/profile')
            //     // .get('pmc/vendor/getUserProfile')
            //     .get("health/user/userprofile")
            //     .then((res) => {
            //       let resData = res.data.data;
            //       if (resData != null) {
            //         setJWTAuthData({
            //           user: { ...resData, role: role },
            //           isLoading: false,
            //           isAuthenticated: true,
            //         });
            //       } else {
            //         logout();
            //         localStorage.clear();
            //       }
            //     })
            //     .catch(() =>
            //       setJWTAuthData({
            //         user: undefined,
            //         isLoading: false,
            //         isAuthenticated: false,
            //       })
            //     );
            // };

            // getAuthUser();
        }
    }, []);

    const signInUser = async ({ userCode, password }) => {
        dispatch({ type: FETCH_START });
        try {
            const { data } = await jwtAxios.post(
                `${baseURLAuth}/admin-authenticate`,
                {
                    userCode,
                    password,
                }
            );
            if (data.message === "SUCCESS") {
                setJWTAuthData({
                    user: { ...data, data: data.data },
                    isLoading: false,
                    isAuthenticated: true,
                });
                localStorage.setItem("token", data.data);

                setAuthToken(data.data);
                dispatch({ type: FETCH_SUCCESS });
            } else {
                setJWTAuthData({
                    ...jwtAuthData,
                    isAuthenticated: false,
                    isLoading: false,
                });
                dispatch({
                    type: FETCH_ERROR,
                    payload: "Invalid User Code",
                });
            }
        } catch (error) {
            setJWTAuthData({
                ...jwtAuthData,
                isAuthenticated: false,
                isLoading: false,
            });
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.errorMessage  || "Something went wrong",
            });
        }
    };

    const signUpUser = async ({ name, email, password }) => {
        dispatch({ type: FETCH_START });
        try {
            const { data } = await jwtAxios.post("users", {
                name,
                email,
                password,
            });
            localStorage.setItem("token", data.token);
            setAuthToken(data.token);
            const res = await jwtAxios.get("/auth");
            setJWTAuthData({
                user: res.data,
                isAuthenticated: true,
                isLoading: false,
            });
            dispatch({ type: FETCH_SUCCESS });
        } catch (error) {
            setJWTAuthData({
                ...jwtAuthData,
                isAuthenticated: false,
                isLoading: false,
            });
            console.log("error:", error.response.data.error);
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.error || "Something went wrong",
            });
        }
    };

    const logout = async () => {
        localStorage.clear();
        setAuthToken();
        setJWTAuthData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
        });
    };

    return (
        <JWTAuthContext.Provider
            value={{
                ...jwtAuthData,
            }}
        >
            <JWTAuthActionsContext.Provider
                value={{
                    signUpUser,
                    signInUser,
                    logout,
                }}
            >
                {children}
            </JWTAuthActionsContext.Provider>
        </JWTAuthContext.Provider>
    );
};
export default JWTAuthAuthProvider;
JWTAuthAuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
