import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
const SeriesVideo = React.lazy(() => import("./Videos/SeriesVideo"));
const Series = React.lazy(() => import("./Videos/Series"));
const Video = React.lazy(() => import("./Videos/Video"));
const AudioAlbum = React.lazy(() => import("./Audios/Album"));
const AudioList = React.lazy(() => import("./Audios/AlbumAudio"));
const Audio = React.lazy(() => import("./Audios/Audio"));
const AddBlog = React.lazy(() => import("./Blogs/AddBlog"));
const BlogStatus = React.lazy(() => import("./Blogs/BlogStatus"));
const Books = React.lazy(() => import("./Books"));
const Magazines = React.lazy(() => import("./Magazines"));
const Reels = React.lazy(() => import("./Reels"));
export const mediasConfigs = [
    // VIDEOS
    {
        permittedRole: RoutePermittedRole.user,
        path: "/videos/series/:id",
        element: <SeriesVideo />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/videos/series",
        element: <Series />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/videos/single",
        element: <Video />,
    },
    // AUDIOS
    {
        permittedRole: RoutePermittedRole.user,
        path: "/audios/album",
        element: <AudioAlbum />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/audios/album/:id",
        element: <AudioList />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/audios/single",
        element: <Audio />,
    },

    // BLOGS

    {
        permittedRole: RoutePermittedRole.user,
        path: "/blogs",
        element: <AddBlog />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/comments",
        element: <BlogStatus />,
    },

    //  BOOKS
    {
        permittedRole: RoutePermittedRole.user,
        path: "/books",
        element: <Books />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/magazines",
        element: <Magazines />,
    },
    {
        permittedRole: RoutePermittedRole.user,
        path: "/reels",
        element: <Reels />,
    },
];
